// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import "sass/website.css";
import "js/website/aos/dist/aos.css";
import "js/website/swiper/swiper-bundle.min.css";

import "js/website/bootstrap/dist/js/bootstrap.bundle.min.js";
import "js/website/typed.js/lib/typed.min.js";
import Swiper from "js/website/swiper/swiper-bundle.min.js";
import AOS from "js/website/aos/dist/aos";
import SmoothScroll from "smooth-scroll";

// import("vendor/smooth-scroll/smooth-scroll.js")
//   .then(function () {
//     new SmoothScroll("a[href*=\"#\"]");
//   });

const images = require.context("../images", true);
const imagePath = (name) => images(name, true);

require("@rails/ujs").start();
require("turbolinks").start();
require("@rails/activestorage").start();
require("channels");

document.addEventListener("DOMContentLoaded", function () {
  var scroll = new SmoothScroll('a[href*="#"]', {
    speed: 300,
    easing: "easeInOutQuad",
  });

  AOS.init({
    offset: 0,
    once: true,
    easing: "ease-out",
  });

  var swiper = new Swiper(".swiper-container", {
    slidesPerView: 2,
    spaceBetween: 20,
    breakpoints: {
      0: {
        slidesPerView: 2,
        spaceBetween: 30,
      },
      480: {
        slidesPerView: 3,
        spaceBetween: 30,
      },
      768: {
        slidesPerView: 4,
        spaceBetween: 40,
      },
      1024: {
        slidesPerView: 6,
        spaceBetween: 20,
      },
    },
  });
  //Muse Switch Toggle JavaScript
  var elem = document.getElementById("switch-content");

  function toggleClass() {
    elem.classList.toggle("switch-hide");
  }
});
